<template>
  <div elevation="0" class="mt-4 mb-8">
    <div>
      <h3 class="primary--text mb-2">{{ blockTitle }}</h3>
      <span v-if="hint">(審核通過後隨時都可以再加入)</span>
    </div>

    <v-divider></v-divider>

    <template v-if="readonly">
      <liffMemberItemTemplate>
        <template v-slot:avatar>
          <memberAvatar lock />
        </template>
        <template v-slot:content>
          <p class="primary--text ml-2 mb-0">審核通過後隨時都可以邀請人</p>
        </template>
      </liffMemberItemTemplate>
    </template>

    <template v-else>
      <template v-for="(item, index) in membersObj">
        <member-list :key="index" :memberRole="index" :members="item" :control="control" :providerId="providerId"
          :storeId="storeId" :deleteItemCallback="deleteItemCallback" :role="role" :memberRoles="memberRoles" :refresh="refresh" />
      </template>
      <!-- <member-list main :members="masters" :control="control" :providerId="providerId" :storeId="storeId"
        :deleteItemCallback="deleteItemCallback" :role="role" />
      <member-list :members="otherMembers" :control="control" :providerId="providerId" :storeId="storeId"
        :deleteItemCallback="deleteItemCallback" :role="role" /> -->
    </template>

    <v-row v-if="readonly || showAction">
      <v-col cols="8">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" depressed v-if="showInviteBtn" :disabled="readonly" width="100%"
              color="primary">{{
                $t("action.send_invite") }}</v-btn>
          </template>
          <v-list>
            <template v-for="(item, index) in ['co_manager']">
              <v-list-item v-if="item != 'main_manager'" :key="index" @click="send(item)">
                <v-list-item-title>{{ $t(item) }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

      </v-col>
      <v-col cols="4">
        <v-btn depressed v-if="showRefreshBtn" :disabled="readonly" width="100%" color="primary" outlined
          @click="refresh">{{ $t("action.refresh") }}</v-btn>
      </v-col>
    </v-row>

    {{ liffError }}

  </div>
</template>

<script>
export default {
  components: {
    liffMemberItemTemplate: () =>
      import("@/modules/member/components/liffMemberItemTemplate.vue"),
    memberList: () => import("@/modules/member/components/liffMemberList.vue"),
    memberAvatar: () => import("@/modules/member/components/memberAvatar.vue"),
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    autoInvite: {
      type: Boolean,
      default: false,
    },
    // 是否能邀請/刪除會員
    control: {
      type: Boolean,
      default: false,
    },
    isMainManager: {
      type: Boolean,
      default: false,
    },
    shareConfig: {
      type: Array,
      default: () => [],
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: "provider",
      validator: (value) => {
        return ["store", "provider"].includes(value);
      },
    },
    hint: {
      type: Boolean,
      default: false,
    },
    providerId: String,
    storeId: String,
    // otpApi: {
    //   type: Function
    // },
  },
  data: () => ({
    liffError: null,
    memberTypes: {
      store: ['signer'],
      provider: ['picker', 'dispatcher', 'freightman']
    },
    otpCode: '',
    roleName: '',
    inviteRole: '',
  }),
  computed: {
    canShareText() {
      console.warn('isMobile', this.$helper.isMobile())
      if (this.$helper.isMobile() === false) {
        return false
      }
      return !!window.navigator.share
    },
    providerName() {
      return this.$store.getters[`member/providerName`]
    },
    layout() {
      return this.$helper.whereLayout()
    },
    frontendDomain() {
      return this.$store.getters[`base/frontendDomain`]
    },
    inviteUrl() {
      if (this.layout == 'provider') {
        return `${this.frontendDomain}/linerp/provider/join/${this.providerId}?code=${this.otpCode}`
      }
      return `${this.frontendDomain}/provider-store/${this.providerId}/store/join/${this.storeId}?code=${this.otpCode}`
    },
    shareTitle() {
      if (this.layout == 'provider') {
        return `我要邀請你加入${ this.providerName }的成員`
      }
      return `邀請你加入${this.storeName}訂購${this.layoutProviderName}的行列`
    },
    shareText() {
      if (this.layout == 'provider') {
        return `加入供應商，請依照以下步驟操作
1. 加入Linerp官方帳號: https://line.me/R/ti/p/${this.linerpAccountId}
2. 點擊此連結成為${this.roleName}: ${this.inviteUrl}
`
      }
      return `加入我的商家，請依照以下步驟操作
1. 加入官方帳號: https://line.me/R/ti/p/${this.layoutProviderLineAtId}
2. 點擊此連結成為${this.roleName}: ${this.inviteUrl}`
    },
    linerpAccountId() {
      return this.$store.getters[`base/eagleEnv`].linerpAccountId
    },
    storeName() {
      return this.$store.getters[`member/storeName`]
    },
    layoutProviderName() {
      return this.$store.getters[`base/layoutProviderName`]
    },
    layoutProviderLineAtId() {
      return this.$store.getters[`base/layoutProviderLineAtId`]
    },
    memberRoles() {
      let arr = ['main_manager', 'co_manager']
      arr = arr.concat(this.memberTypes[this.role])
      return arr
    },
    membersObj() {
      const vm = this
      const obj = {}
      let curr
      for (const i in this.memberRoles) {
        curr = this.memberRoles[i]
        let data = []
        switch (curr) {
          case 'main_manager':
            data = vm.members.filter(member => member.isMaster)
            break;
          case 'co_manager':
            data = vm.members.filter(member => !member.isMaster && Object.prototype.hasOwnProperty.call(member, 'roles') && (!member.roles || member.roles.length == 0))
            break;
          default:
            data = vm.members.filter(member => Object.prototype.hasOwnProperty.call(member, 'roles') && member.roles && member.roles.includes(curr))
            break;
        }
        obj[curr] = data
      }

      return obj
    },
    masters() {
      return this.members.filter(member => member.isMaster)
    },
    otherMembers() {
      return this.members.filter(member => !member.isMaster)
    },
    showInviteBtn() {
      if (this.readonly) return true
      if (!this.control) return false;
      if (!this.isMainManager) return false;
      return true;
    },
    showRefreshBtn() {
      return true;
    },
    tokenRole() {
      return this.$store.getters['token/tokenRole']
    },
    blockTitle() {
      if (this.role == "provider") return this.$t("provider.co-maintainer");
      if (this.role == "store") return this.$t("store.co-maintainer");
      return "";
    },
    otpApi() {
      const params = this.inviteRole ? {roles: [this.inviteRole]} : {}
      if (this.layout === 'provider') {
        return () => this.$api.collection.providerApi.inviteConfig(this.providerId, params)
      }
      return () => this.$api.collection.storeApi.inviteConfig(this.storeId, params)
    },
  },
  mounted() {
    if (this.autoInvite) {
      this.send();
    }
    console.warn('this.layout name', this.layout)
  },
  methods: {
    deleteItemCallback(memberItem) {
      const index = this.members.findIndex(member => member.id == memberItem.id)
      if (index < 0) return
      this.members.splice(index, 1)
    },
    refresh() {
      this.$emit("refresh");
    },
    async send(role) {
      if (!this.isMainManager) return;
      console.warn('send invite url', role, this.shareConfig)

      this.$store.dispatch('loading/active')
      try {

        role = role != 'co_manager' ? role : null
        this.inviteRole = role
        this.roleName = !role ? this.$t('main_manager') : this.$t(role)
        await this.$emit('setInviteRole', role)

        const otpCode = await this.otpApi()
        this.otpCode = otpCode
        this.$emit('setOtpCode', otpCode)
        await this.$nextTick()

        // console.warn('this.shareTitle', this.shareTitle)
        // console.warn('this.shareText', this.shareText)
        // console.log('this.inviteUrl', this.inviteUrl)

        if (this.canShareText) {
          window.navigator.share({
            title: this.shareTitle,
            text: this.shareText,
          })
        } else {
          this.$apopup.base({
            title: `邀請成為${this.roleName}`,
            width: '650px',
            shareTitle: this.shareTitle,
            shareText: this.shareText,
            inviteUrl: this.inviteUrl,
            bodySlot: () => import('@/modules/member/components/shareInviteUrlPopup.vue'),
          })
        }
      } catch (error) {
        console.warn(error)
        this.$snotify.error(null, "發生意外的錯誤");
      } finally {

        this.$emit('setInviteRole', '')
        this.$emit('setOtpCode', '')

        this.$store.dispatch('loading/close')
      }
    },
  },
};
</script>